.blanket {
    display: none;
    z-index: 20;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    opacity: 0;

    &.open {
        display: flex;
        animation-name: showPups;
        animation-duration: 450ms;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .pop-message {
        width: 320px;
        padding: 20px;
        border: 2px solid $yellow-color;
        border-radius: 6px;
        background-color: $pop-mess;
        @include btn-shadow;
        position: relative;

        .pm-title {
            position: relative;
            padding-left: 50px;
            font-weight: 500;

            &:before {
                position: absolute;
                top: 0;
                left: -3px;
                content: url("../img/img-cat/group.svg");
            }
        }

        .list-function {
            padding-left: 20px;
            list-style-type: none;

            .lf-item {
                position: relative;
                margin-bottom: 13px;

                &:before {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: -21px;
                    content: "\2605";
                }
            }
        }

        .fcheck-input {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            vertical-align: middle;
        }

        .fcheck-input-label {
            color: $red-color;
            text-decoration: underline;
        }

        .close-pop {
            width: 100%;
            border: none;
        }
    }
}

.new-blanket {
    display: none;
    z-index: 9;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(18,18,18, .4);
    opacity: 0;

    &.open {
        display: flex;
        animation-name: showPups;
        animation-duration: 450ms;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .popup {
        position: relative;
        margin: 0 12px;
        padding: 20px 14px;
        border-radius: 6px;
        background-color: #FFFFFF;

        .modal-close {
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 12px;
            padding: 0 4px;
            transform: rotate(45deg);
            font-size: 26px;
            line-height: 1;
            cursor: pointer;

        }

        .bl-title {
            font-size: 30px;
            text-align: center;
            text-transform: uppercase;
        }

        .name-ordner {
            border-bottom: 1px solid $gray-form;

            .folder-ico,
            .chip-ico {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 8px;
                vertical-align: middle;
            }

            .folder-ico {
                background: url("../img/img-cat/folder.svg") center /cover no-repeat;
            }

            .chip-ico {
                background: url("../img/chip.svg") center /cover no-repeat;
            }

            input {
                margin-bottom: 9px;
                padding: 6px 14px;
                border-top: none;
                border-right: none;
                border-bottom: none;
                border-left: 1px solid $gray-form;
                color: $gray-form;

                &:focus,
                &:focus-visible {
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                    outline: none;
                }

            }
        }

        .inp-email {
            border-bottom: 1px solid $gray-form;

            .email-ico {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 8px;
                background: url("../img/img-cat/mail.svg") center /cover no-repeat;
                vertical-align: middle;
            }

            input {
                max-width: 280px;
                margin-bottom: 9px;
                padding: 6px 14px;
                border-top: none;
                border-right: none;
                border-bottom: none;
                border-left: 1px solid $gray-form;
                color: $gray-form;

                &:focus,
                &:focus-visible {
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                    outline: none;
                }

            }
        }

        .inp-pwd {
            border-bottom: 1px solid $gray-form;

            .pwd-ico {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 8px;
                background: url("../img/img-cat/padlock.svg") center /cover no-repeat;
                vertical-align: middle;
            }

            input {
                max-width: 280px;
                margin-bottom: 9px;
                padding: 6px 14px;
                border-top: none;
                border-right: none;
                border-bottom: none;
                border-left: 1px solid $gray-form;
                color: $gray-form;

                &:focus,
                &:focus-visible {
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                    outline: none;
                }
            }
        }

        .remember-me {
            padding-left: 1.5em;
            font-size: 14px;

            input[type="checkbox"] {
                appearance: none;
                width: 1em;
                height: 1em;
                margin-top: .25em;
                margin-left: -1.5em;
                float: left;
                border: 1px solid $gray-form;
                border-radius: .25em;
                background-color: #fff;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: top;

                &:checked {
                    border-color: $green-light;
                    background-color: $green-light;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
                }
            }

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        .btn-ordner {
            display: flex;
            justify-content: space-between;

            a {
                width: 47%;
                text-align: center;
            }
        }

        .btn-login {
            text-align: center;

            a {
                width: 60%;
                text-align: center;
            }

            &.btn-reg {
                margin-top: 1.8rem;
            }
        }

        .link-register {
            text-align: center;

            a {
                color: $green-light;
                text-decoration: none;
            }
        }
    }


}

@media (min-width: 768px) {
    .new-blanket {
        .popup {
            padding: 30px 60px;
        }
    }
}

@media (min-width: 1200px) {

    .blanket {

        .pop-message {

            &:before {
                display: block;
                z-index: 10;
                position: absolute;
                top: 60px;
                left: -38px;
                width: 0;
                height: 0;
                transform: rotate(12deg);
                border-top: 15px solid $yellow-color;
                border-right: 20px solid $yellow-color;
                border-bottom: 15px solid transparent;
                border-left: 20px solid transparent;
                content: "";
            }

            &:after {
                display: block;
                z-index: 11;
                position: absolute;
                top: 62px;
                left: -33px;
                transform: rotate(12deg);
                border-top: 13px solid $beige-color;
                border-right: 18px solid $beige-color;
                border-bottom: 13px solid transparent;
                border-left: 18px solid transparent;
                content: "";
            }
        }
    }

    .new-blanket {

        .popup {
            margin: 0;
            padding: 60px 140px;
        }
    }
}

@keyframes showPups {
    0% {
        opacity: 0.1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}
