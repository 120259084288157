.create-lernchip {
    background-color: $bg-contact;

    .main-content {

        .cl-topflex {
            padding: 30px 0;
            border-bottom: 1px solid $gray-color;

            .title-section {
                font-size: 1.7rem;
                font-weight: 500;
            }

            .cl-subtitle {
                font-size: 1.3rem;
            }

            .cl-list {
                padding: 0;
                list-style: decimal;
                list-style-position: inside;
            }

            .cl-img {
                text-align: center;
            }
        }

        .link-zurueck {
            display: none;
            margin: 30px 0 16px;

            a {
                color: inherit;
            }
        }

        .cl-wahl {
            padding: 30px 0 60px;

            .title-section {
                font-size: 1.3rem;
                text-align: center;
            }
        }
    }

    .flex-cat {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cat-item {
            flex: 0 1 48%;
            height: 220px;
            text-align: center;
            background-color: #FFFFFF;
            margin-bottom: 0.8rem;
            border-radius: 3px;

            .title-chip {
                font-size: 1.1rem;
            }

            &:hover {
                .flip-card {
                    transform: rotateY(180deg);
                }
            }
        }

        .flip-card {
            position: relative;
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
            text-align: center;
            transition: transform 0.8s;
        }

        .cat-item-front,
        .cat-item-back {
            -webkit-backface-visibility: hidden; /* Safari */
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
        }

        .cat-item-front {
            padding: 40px 10px;
            background-color: #FFFFFF;

            .block-ico {
                margin-bottom: 16px;
            }
        }

        .cat-item-back {
            padding: 20px 10px 30px;
            transform: rotateY(180deg);
            background-color: $btn-color;
            color: #FFFFFF;

            .list-item {
                margin: 0.8rem auto;
                padding: 0;
                list-style-position: inside;
                list-style-image: url("../img/star-w.png");
                list-style-type: none;
                text-align: left;
                font-size: 12px;
            }

            .go {
                display: block;
                position: absolute;
                bottom: 18px;
                right: 15px;
                color: $yellow-color;
                text-align: right;
                text-decoration: none;

                &:after {
                    position: absolute;
                    right: 0;
                    bottom: -16px;
                    transform: scale(3.6);
                    content: "\2192";
                }
            }
        }
    }
}

.cl-hilfe {
    background-color: #FFFFFF;

    .title-section {
        text-align: center;
        max-width: 820px;
        margin: 30px auto;
    }

    .info-section {
        text-align: center;
        max-width: 820px;
        margin: 0 auto 1rem;
    }

    .wrap-movie {
        --video--width: 560;
        --video--height: 315;
        position: relative;
        height: 0;
        margin: 0 auto 2rem;
        padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
        overflow: hidden;
        background: black;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .info-subtitle {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 1.2rem;
    }

    .ma-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .ma-item {
        flex: 0 1 48%;
        margin-bottom: 1rem;
        text-align: center;

        &.item-one {
            display: none;
        }

        .wr-img {
            margin-bottom: 0.6rem;
        }

        .item-title {
            font-size: 1.2rem;
            margin-bottom: 0.6rem;

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        .item-info {
            margin-bottom: 1rem;
        }
    }
}

@media (min-width: 768px) {

    .create-lernchip {
        .flex-cat {
            .cat-item {
                margin-bottom: 1rem;
            }
        }
    }

    .cl-hilfe {

        .movie-container {
            width: 560px;
            margin: 0 auto;
        }

        .info-subtitle {
            margin-bottom: 1.5rem;
        }
    }
}

@media (min-width: 992px) {

	.create-lernchip {

        .main-content {

            .cl-topflex {
                padding: 40px 0;
                display: flex;
                justify-content: space-between;
            }

            .cl-info {
                flex: 0 1 60%;
            }

            .cl-img {
                flex: 0 1 39%;
            }

            .link-zurueck {
                display: block;
            }
        }

        .flex-cat {
            .cat-item {
                flex: 0 1 24%;
                margin-bottom: 1.1rem;
            }

            .cat-item-front {
                padding: 40px 20px;
            }

            .cat-item-back {
                padding: 40px 20px;
            }
        }
    }

    .cl-hilfe {

        .ma-item {
            flex: 0 1 32%;
            margin-bottom: 1rem;

            &.item-one {
                display: block;
            }

            .chanel-link {

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }
}

@media (min-width: 1200px) {

    .create-lernchip {

        .main-content {

            .cl-wahl {
                padding: 50px 0 60px;

                .title-section {
                    margin-bottom: 1.8rem;
                }
            }
        }
    }

    .cl-hilfe {

        .title-section {
            margin: 50px auto 30px;
        }

        .ma-item {
            flex: 0 1 23%;
            margin-bottom: 1.5rem;
        }
    }
}

@media (min-width: 1440px) {

    .cl-hilfe {

        .info-section {
            margin: 0 auto 1.8rem;
        }

        .info-subtitle {
            margin-bottom: 1.8rem;
        }

        .ma-item {
            flex: 0 1 18%;
            margin-bottom: 2rem;
        }
    }
}
