.learnchip-type {
    background-color: $bg-contact;

    .main-content {

        .lt-topflex {
            padding: 30px 0;
            border-bottom: 1px solid $gray-color;

            .lt-info {
                text-align: center;
            }

            .title-section {
                font-size: 1.7rem;
                font-weight: 500;
            }

            .lt-text {
                font-size: 1.2rem;
            }

            .lt-img {
                text-align: center;
            }
        }

        .link-zurueck {
            display: none;
            margin: 30px 0 16px;

            a {
                color: inherit;
            }
        }

        .wr-btn {
            text-align: center;
        }
    }

    .list-types {
        padding: 2rem 0;

        .lt-flex {
            margin-bottom: 2rem;
        }

        .lt-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #FFFFFF;
            padding: 0.6rem 0.8rem;
            margin-bottom: 1rem;

            .wr-img {
                flex: 0 1 30%;

                img {
                    display: block;
                }
            }

            .wr-txt {
                flex: 0 1 70%;
                margin: 0;
            }

            &:hover {
                background-color: $btn-color;

                .wr-txt {
                    color: #FFFFFF;
                }
            }

            &.selected {
                background-color: $btn-color;

                .wr-txt {
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media (min-width: 768px) {
	.learnchip-type {

        .list-types {

            .lt-flex {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .lt-item {
                flex: 0 1 48.6%;
            }
        }
    }
}

@media (min-width: 992px) {

	.learnchip-type {

        .main-content {

            .lt-topflex {
                padding: 40px 0;
                display: flex;
                justify-content: space-between;
            }

            .lt-info {
                flex: 0 1 60%;
            }

            .lt-img {
                flex: 0 1 39%;
            }

            .link-zurueck {
                display: block;
            }
        }
    }

    .list-types {
        padding: 3rem 0;
    }
}

@media (min-width: 1200px) {

	.learnchip-type {

        .main-content {

            .lt-topflex {
                width: 83%;
                margin: 0 auto;

                .lt-info {
                    text-align: left;
                }
            }
        }

        .list-types {
            width: 82%;
            margin: 0 auto;
            padding: 3.8rem 0;

            .lt-item {

                .wr-img {
                    flex: 0 1 25%;
                }

                .wr-txt {
                    flex: 0 1 75%;
                    margin: 0;
                }
            }
        }
    }
}
