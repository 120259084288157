.manage-page {

	.second-menu {
		margin-bottom: 30px;

		.flex-one {
			margin-bottom: 14px;
		}

		.switch-menu {
			display: inline-block;
			margin-right: 14px;
			padding: 10px 12px;
			border-radius: 6px;
			background-color: #FFFFFF;
			color: $body-color;
			text-decoration: none;
			@include btn-shadow;
			line-height: 26px;
			vertical-align: top;

			span {
				display: inline-block;
				width: 25px;
				height: 25px;
				margin-right: 5px;
				background-image: url("../img/img-cat/btn_sprites.png");
				background-position: -10px -10px;
				vertical-align: middle;
			}
		}

		.edit-menu {
			display: inline-block;
			margin-right: 10px;
			padding: 9.5px 10px;
			border: 1px solid #FFFFFF;
			border-radius: 6px;
			background-color: #FFFFFF;
			color: $body-color;
			text-decoration: none;
			@include btn-shadow;
			cursor: pointer;
			transition: all 350ms ease-out;

			&:hover {
				border: 1px solid $green-color;
				background-color: $green-color;
			}

			span {
				display: block;
				width: 25px;
				height: 25px;
			}

			&.btn-edit {

				span {
					background-image: url("../img/img-cat/btn_sprites.png");
					background-position: -100px -100px;
				}

				&:hover {
					span {
						background-position: -145px -10px;
					}
				}
			}

			&.btn-del {

				span {
					background-image: url("../img/img-cat/btn_sprites.png");
					background-position: -10px -100px;
				}

				&:hover {
					span {
						background-position: -55px -100px;
					}
				}
			}
		}


		.flex-two {
			margin-bottom: 14px;

			a {
				margin-right: 10px;
				margin-bottom: 10px;
				vertical-align: middle;
				@include btn-shadow;

				img {
					display: inline-block;
					vertical-align: text-top;
				}

				&:last-child {
					margin-right: 0;
				}

				span {
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 10px;
					vertical-align: sub;
				}

				.btn-plus {
					background-image: url("../img/img-cat/btn_sprites.png");
					background-position: -145px -55px;
				}

				.btn-upload {
					background-image: url("../img/img-cat/btn_sprites.png");
					background-position: -10px -145px;
				}

				&:hover {
					.btn-plus {
						background-position: -145px -95px;
					}

					.btn-upload {
						background-position: -50px -145px;
					}
				}
			}
		}

		.flex-three {


			.wr-search {
				display: inline-block;
				position: relative;
				width: 100%;
				margin-bottom: 14px;

				.wr-img {
					display: inline-block;
					position: absolute;
					top: 0;
					right: 4px;
					padding: 12px 8px 8px;
					cursor: pointer;
				}
			}

			input[type="text"] {
				width: 100%;
				padding: 11px 16px;
				border: 1px solid #FFFFFF;
				border-radius: 6px;
				line-height: 1.4;
				@include tile-shadow;

				&:focus {
					border: 1px solid #FFFFFF;
					outline: none;
				}

				&:focus-visible {
					border: 1px solid #FFFFFF;
					outline: none;
				}
			}

			.sort-chips {
				appearance: none;
				width: 100%;
				padding: 13px 30px 13px 15px;
				border: 1px solid #FFFFFF;
				border-radius: 6px;
				background-color: #FFFFFF;
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
				background-position: right 10px center;
				background-repeat: no-repeat;
				background-size: 16px 12px;
				vertical-align: top;

				&:focus {
					border: 1px solid #FFFFFF;
					outline: none;
				}

				&:focus-visible {
					border: 1px solid #FFFFFF;
					outline: none;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.manage-page {

		.second-menu {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

				.flex-three {
					flex: 0 1 100%;
				}
		}
	}
}

@media (min-width: 1200px) {

	.manage-page {

		.second-menu {
			margin-bottom: 40px;

			.switch-menu {
				display: none;
			}
		}
	}
}

@media (min-width: 1400px) {
	.manage-page {

		.second-menu {
			margin-bottom: 30px;

			.flex-one {
				margin-bottom: 0;
			}

			.flex-two {
				margin-bottom: 0;

				a {

					&:last-child {
						margin-right: 10px;
					}
				}
			}

			.flex-three {
				flex: 0 1 auto;

				.wr-search {
					width: auto;
					margin-right: 10px;
					margin-bottom: 0;

					.wr-img {
						right: 14px;
					}
				}

				input[type="text"] {
					width: 260px;
				}

				.sort-chips {
					width: auto;
				}
			}
		}
	}
}
