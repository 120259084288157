.cp-body {
    background-color: #FFFFFF;

    .cf-container {
        margin-right: 15px;
        margin-left: 15px;

        .cf-title {
            text-align: center;
        }

        .show-warn {
            min-height: 18px;
            color: $red-light;
            margin-bottom: 1.5rem;
        }
    }
}

.contact-page {
    margin-bottom: 20px;
    padding: 40px 0 30px;
    border-bottom: 1px solid $gray-color;
    background-color: #FFFFFF;

    .page-title {
        font-size: 30px;
        text-align: center;
    }

    .advert-text {
        font-size: 18px;

        &.tb {
            color: $black-color;
            font-size: 25px;
        }
    }

    .advert-list {
        padding-left: 30px;
        list-style: none;

        li {
            display: block;
            position: relative;
            margin-bottom: 4px;
            font-size: 18px;
            line-height: 1.6;

            &::before {
                display: block;
                position: absolute;
                top: 2px;
                left: -30px;
                width: 20px;
                height: 20px;
                content: url("../img/star-b.png");
            }
        }
    }

    .contact-img {

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.cont-form {

    .block-text {
        border-bottom: 1px solid $gray-form;
        transition: border-color 350ms ease-out;
        margin-bottom: 1.2rem;

        span {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 8px;
            background-image: url("../img/reg-page/form_icons.png");
            vertical-align: middle;

            &.user-ico {
                background-position: -110px -260px;
            }

            &.company-ico {
                background-position: -60px -210px;
            }

            &.email-ico {
                background-position: -60px -160px;
            }

            &.phone-ico {
                background-position: -260px -210px;
            }

            &.mess-ico {
                background-position: -160px -10px;
            }
        }

        input {
            max-width: 280px;
            margin-bottom: 9px;
            padding: 6px 14px;
            border-top: none;
            border-right: none;
            border-bottom: none;
            border-left: 1px solid $gray-form;
            color: $gray-form;

            &:focus,
            &:focus-visible {
                border-top: none;
                border-right: none;
                border-bottom: none;
                outline: none;
            }
        }

        &.focused {
            border-bottom: 1px solid $yellow-color;

            span {

                &.user-ico {
                    background-position: -260px -260px;
                }

                &.company-ico {
                    background-position: -160px -210px;
                }

                &.email-ico {
                    background-position: -160px -160px;
                }

                &.phone-ico {
                    background-position: -60px -260px;
                }

                &.mess-ico {
                    background-position: -160px -110px;
                }
            }

            input {
                border-left: 1px solid $yellow-color;
                color: $yellow-color;

                &::placeholder {
                    color: $yellow-color;
                }
            }
        }

        &.focused.novalid {
            border-bottom: 1px solid $yellow-color;

            span {

                &.user-ico {
                    background-position: -260px -260px;
                }

                &.company-ico {
                    background-position: -160px -210px;
                }

                &.email-ico {
                    background-position: -160px -160px;
                }

                &.phone-ico {
                    background-position: -260px -110px;
                }

                &.mess-ico {
                    background-position: -160px -110px;
                }
            }

            input {
                border-left: 1px solid $yellow-color;
                color: $yellow-color;

                &::placeholder {
                    color: $yellow-color;
                }
            }
        }

        &.novalid {
            border-bottom: 1px solid $red-light;

            span {

                &.user-ico {
                    background-position: -210px -260px;
                }

                &.company-ico {
                    background-position: -110px -210px;
                }

                &.email-ico {
                    background-position: -110px -160px;
                }

                &.phone-ico {
                    background-position: -10px -260px;
                }

                &.mess-ico {
                    background-position: -160px -60px;
                }
            }

            input {
                border-left: 1px solid $red-light;
                color: $red-light;

                &::placeholder {
                    color: $red-light;
                }
            }
        }

        &.valid {
            border-bottom: 1px solid $black-color;

            span {

                &.user-ico {
                    background-position: -160px -260px;
                }

                &.company-ico {
                    background-position: -10px -210px;
                }

                &.email-ico {
                    background-position: -10px -160px;
                }

                &.phone-ico {
                    background-position: -260px -160px;
                }

                &.mess-ico {
                    background-position: -110px -110px;
                }
            }

            input {
                border-left: 1px solid $black-color;
                color: $black-color;

                &::placeholder {
                    color: $black-color;
                }
            }
        }
    }

    .have-read {
        text-align: center;
        margin-bottom: 1.4rem;

        a {
            color: inherit;
        }

        input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 18px;
            height: 18px;
            margin-top: 2px;
            margin-right: 10px;
            border: 1px solid rgba(0,0,0,.25);
            background-color: #fff;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.873.66L4.447 9.087l-3.32-3.32L0 6.893l4.446 4.447L14 1.786 12.873.66z' fill='%23fff'/%3e%3c/svg%3e");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: top;

            &:checked {
                background-color: $green-color;
            }
        }
    }
}

.btn-contact {
    margin-bottom: 40px;
    text-align: center;

    .btn {
        min-width: 184px;
        margin-bottom: 18px;
    }
}

.sucs-blanket {
    display: none;
    z-index: 9;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(18,18,18, .4);
    opacity: 0;

    &.open {
        display: flex;
        animation-name: showPups;
        animation-duration: 450ms;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .popup {
        position: relative;
        margin: 0 12px;
        padding: 20px 14px;
        border-radius: 6px;
        background-color: #FFFFFF;

        .modal-close {
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 12px;
            padding: 0 4px;
            transform: rotate(45deg);
            font-size: 26px;
            line-height: 1;
            cursor: pointer;

        }

        .bl-title {
            font-size: 30px;
            text-align: center;
            text-transform: uppercase;
        }

        .sucs-icon {
            text-align: center;

            .check-ico {
                display: block;
                margin: 0 auto;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") $green-light;
            }
        }

        .sucs-mess {
            text-align: center;
            font-size: 18px;
            margin-bottom: 30px;
        }

        .sucs-btn {
            text-align: center;
        }
    }
}

@media (min-width: 768px) {

    .contact-page {
        margin-bottom: 50px;
        padding: 40px 0 50px;

        .page-title {
            margin-bottom: 2.5rem;
            font-size: 40px;
            font-weight: 500;
        }

        .f-advert {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .fa-info {
                flex: 0 1 50%;
            }
        }
    }

    .cont-form {

        .have-read {
            margin-bottom: 2.4rem;
        }
    }

    .cp-body {

        .cf-container {
            max-width: 540px;
            margin-right: auto;
            margin-left: auto;

            .cf-title {
                margin-bottom: 1.5rem;
            }
        }
    }

    .sucs-blanket {

        .popup {
            max-width: 820px;
            margin: 0;
            padding: 30px 40px;
        }
    }
}
