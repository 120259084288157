.ty-body {
    background-color: #FFFFFF;
}

.ty-page {
    margin-bottom: 20px;
    padding: 40px 0 30px;
    background-color: #FFFFFF;

    .page-title {
        font-size: 30px;
        text-align: center;
    }

    .ty-img {
        text-align: center;
        margin-bottom: 20px;

        img {
            display: block;
            width: 100%;
        }
    }

    .ty-text {
        text-align: center;
        margin-bottom: 40px;
    }
}

@media (min-width: 768px) {
    .ty-page {

        .ty-img {
            margin-bottom: 30px;

            img {
                width: auto;
                margin: 0 auto;
            }
        }
    }
}
